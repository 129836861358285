// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function BolaIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 65 51"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M15.74 44.283c.46.46.46 1.205 0 1.665l-4.708 4.707a1.177 1.177 0 01-1.664-1.664l4.708-4.708c.46-.46 1.204-.46 1.664 0zm-5.262-6.248a1.177 1.177 0 01-.696 1.512l-6.243 2.31a1.177 1.177 0 11-.817-2.207l6.244-2.31a1.177 1.177 0 011.512.695zm-.981-7.638a1.177 1.177 0 01-1.152 1.201l-6.656.141a1.177 1.177 0 01-.05-2.353l6.656-.141a1.177 1.177 0 011.202 1.152zm.841-7.97c-.25.6-.94.884-1.54.634L2.654 20.5a1.177 1.177 0 11.905-2.173l6.146 2.56c.6.25.884.94.634 1.54zm4.229-7.39a1.177 1.177 0 01-1.664-.02l-4.65-4.765a1.177 1.177 0 011.685-1.644l4.65 4.765c.454.465.444 1.21-.02 1.664zM48.99 44.283a1.179 1.179 0 000 1.665l4.709 4.707a1.177 1.177 0 001.664-1.664l-4.708-4.708a1.177 1.177 0 00-1.664 0zm5.263-6.248c-.225.61.086 1.286.696 1.512l6.243 2.31a1.177 1.177 0 10.817-2.207l-6.244-2.31a1.177 1.177 0 00-1.512.695zm.981-7.638a1.177 1.177 0 001.152 1.201l6.656.141a1.177 1.177 0 00.05-2.353l-6.656-.141a1.177 1.177 0 00-1.202 1.152zm-.841-7.97c.25.6.94.884 1.54.634l6.145-2.561a1.177 1.177 0 10-.906-2.173l-6.145 2.56c-.6.25-.884.94-.634 1.54zm-4.229-7.39c.465.454 1.21.445 1.664-.02l4.65-4.765a1.177 1.177 0 00-1.685-1.644l-4.65 4.765a1.177 1.177 0 00.02 1.664zM33.048 11.38c.433.485.39 1.229-.096 1.66-.322.287-.657.577-1.003.876-1.697 1.467-3.632 3.14-5.285 5.528-1.958 2.826-3.518 6.674-3.518 12.3 0 5.246 1.546 8.619 3.467 11.007 1.578 1.962 3.403 3.276 5.06 4.468.414.298.818.588 1.204.88a1.177 1.177 0 01-1.416 1.88c-.323-.243-.68-.5-1.064-.776-1.67-1.197-3.818-2.739-5.618-4.977-2.264-2.814-3.987-6.71-3.987-12.482 0-6.11 1.71-10.425 3.937-13.64 1.839-2.656 4.033-4.55 5.733-6.016.329-.284.64-.551.925-.806a1.177 1.177 0 011.661.097z"
        }
        fill={"currentColor"}
      ></path>

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M32.075 12.164a1.177 1.177 0 00.096 1.661c3.616 3.218 9.021 8.262 9.021 17.952 0 7.73-5.408 13.657-8.946 16.321a1.177 1.177 0 101.416 1.88c3.785-2.85 9.884-9.388 9.884-18.201 0-10.763-6.102-16.41-9.81-19.71a1.177 1.177 0 00-1.661.097z"
        }
        fill={"currentColor"}
      ></path>

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M32.17 48.16c9.914 0 17.951-8.038 17.951-17.952 0-9.915-8.037-17.952-17.952-17.952-9.914 0-17.952 8.037-17.952 17.952 0 9.914 8.038 17.952 17.952 17.952zm0 2.448c11.266 0 20.4-9.134 20.4-20.4 0-11.267-9.134-20.4-20.4-20.4-11.267 0-20.4 9.133-20.4 20.4 0 11.266 9.133 20.4 20.4 20.4z"
        }
        fill={"currentColor"}
      ></path>

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M15.3 40.408c0-.65.527-1.177 1.177-1.177h30.6a1.177 1.177 0 110 2.354h-30.6c-.65 0-1.177-.527-1.177-1.177z"
        }
        fill={"currentColor"}
      ></path>

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M32.17 0c.65 0 1.176.527 1.176 1.177v8.238h4.316a1.177 1.177 0 010 2.354h-4.316v9.416h15.3a1.177 1.177 0 110 2.354h-15.3v25.5a1.177 1.177 0 11-2.354 0v-25.5H14.908a1.177 1.177 0 010-2.354h16.084v-9.416h-4.315a1.177 1.177 0 110-2.354h4.315V1.177C30.992.527 31.52 0 32.17 0z"
        }
        fill={"currentColor"}
      ></path>

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M12.162 31.777c0-.65.526-1.177 1.176-1.177H51a1.177 1.177 0 110 2.354H13.338c-.65 0-1.176-.527-1.176-1.177z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default BolaIcon;
/* prettier-ignore-end */
