// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Bola2Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 52 41"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M12.569 35.36a.94.94 0 010 1.33l-3.76 3.758A.94.94 0 017.48 39.12l3.76-3.759a.94.94 0 011.329 0zm-4.202-4.99a.94.94 0 01-.556 1.208l-4.985 1.845a.94.94 0 01-.653-1.763l4.986-1.845a.94.94 0 011.208.556zm-.784-6.098a.94.94 0 01-.92.96l-5.314.112a.94.94 0 01-.04-1.88l5.315-.112a.94.94 0 01.96.92zm.672-6.364a.94.94 0 01-1.23.506L2.12 16.369a.94.94 0 01.722-1.735L7.75 16.68c.479.2.705.75.506 1.229zm3.377-5.901a.94.94 0 01-1.33-.016L6.59 8.186a.94.94 0 111.345-1.313l3.713 3.805a.94.94 0 01-.016 1.33zM39.12 35.36a.94.94 0 000 1.33l3.759 3.758a.94.94 0 101.329-1.329l-3.76-3.759a.94.94 0 00-1.328 0zm4.201-4.99a.94.94 0 00.555 1.208l4.986 1.845a.94.94 0 00.652-1.763l-4.985-1.845a.94.94 0 00-1.208.556zm.784-6.098a.94.94 0 00.92.96l5.314.112a.94.94 0 00.04-1.88l-5.315-.112a.94.94 0 00-.96.92zm-.672-6.364a.94.94 0 001.229.506l4.907-2.045a.94.94 0 00-.723-1.735L43.94 16.68a.94.94 0 00-.506 1.229zm-3.377-5.901a.94.94 0 001.329-.016l3.713-3.805a.94.94 0 00-1.346-1.313l-3.712 3.805a.94.94 0 00.016 1.33zM26.39 9.086a.94.94 0 01-.078 1.327c-.257.228-.525.46-.8.699-1.356 1.171-2.901 2.508-4.22 4.414-1.564 2.257-2.81 5.33-2.81 9.822 0 4.188 1.235 6.882 2.769 8.789 1.26 1.566 2.717 2.615 4.04 3.567.33.238.653.47.962.702a.94.94 0 11-1.131 1.502c-.258-.194-.544-.4-.85-.62-1.333-.956-3.049-2.186-4.486-3.973-1.807-2.248-3.183-5.359-3.183-9.967 0-4.88 1.365-8.325 3.143-10.892 1.468-2.121 3.22-3.633 4.578-4.804.262-.226.51-.44.738-.643a.94.94 0 011.327.077z"
        }
        fill={"currentColor"}
      ></path>

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M25.612 9.713a.94.94 0 00.077 1.327c2.887 2.569 7.203 6.597 7.203 14.334 0 6.172-4.318 10.905-7.143 13.032a.94.94 0 101.13 1.502c3.022-2.276 7.893-7.497 7.893-14.534 0-8.594-4.873-13.104-7.833-15.739a.94.94 0 00-1.327.078z"
        }
        fill={"currentColor"}
      ></path>

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M25.688 38.455c7.917 0 14.334-6.417 14.334-14.334S33.605 9.786 25.688 9.786s-14.335 6.418-14.335 14.335c0 7.917 6.418 14.334 14.335 14.334zm0 1.955c8.996 0 16.289-7.293 16.289-16.29 0-8.996-7.293-16.289-16.29-16.289-8.996 0-16.289 7.293-16.289 16.29 0 8.996 7.293 16.29 16.29 16.29z"
        }
        fill={"currentColor"}
      ></path>

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M12.217 32.265a.94.94 0 01.94-.94h24.434a.94.94 0 010 1.88H13.157a.94.94 0 01-.94-.94z"
        }
        fill={"currentColor"}
      ></path>

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M25.687 0c.52 0 .94.42.94.94v6.578h3.446a.94.94 0 010 1.88h-3.446v7.518h12.217a.94.94 0 010 1.88H26.627v20.361a.94.94 0 11-1.88 0V18.795H11.904a.94.94 0 110-1.88h12.843V9.399h-3.445a.94.94 0 010-1.88h3.445V.94c0-.52.421-.94.94-.94z"
        }
        fill={"currentColor"}
      ></path>

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M9.711 25.374c0-.52.42-.94.94-.94h30.073a.94.94 0 010 1.88H10.65a.94.94 0 01-.94-.94z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Bola2Icon;
/* prettier-ignore-end */
